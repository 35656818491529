import React, { useRef } from "react";

import footerlogo1 from "./../../images/westwood/detached/logo.svg";
import leftman from "./../../images/westwood/detached/Amit-Airi.webp";
import footerlogo from "./../../images/westwood/detached/logo.svg";
import rightman from "./../../images/westwood/detached/Ashish-Wadhwa.webp";

const DealerInfo = (props) => {

    return (
      <>
      <section className="sec-padd-t bg-light1 deler_footer">
          <div className="container">
            <div className="row g-4 ">
              <div className="col-md-7 m-auto sec-padd-b">
                <h2 className="c_heading text-center">
                  Exclusive Listing Brokerage
                </h2>
                <h4 className="sub-heading text-center">
                  <span>RE/MAX</span> Real Estate Centre Inc., Brokerage
                </h4>
                <div className="m-show footer-logo-div">
                  <img src={footerlogo1} alt="" />
                  <p>amitairi.com</p>
                </div>
              </div>
              <div className="col-md-7 m-auto">
              <h2 className="dealer_number text-center">416-779-8800</h2>
              </div>

              <div className="footer-info">
                <div className="col-md-7 m-auto">
                  <div className="row g-4">
                    <div className="col-md-4">
                      <div className="group-1">
                        <img  className="dealer_img" src={leftman} alt="Amit" />
                        <div className="info-details">
                          <h2>Amit Airi</h2>
                          <p>Broker</p>
                          <h3>519-623-0000</h3>
                          <h3>info@amitairi.com</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 m-hide">
                      <div className="group-m">
                        <img
                          className="position-relative"
                          src={footerlogo}
                          alt=""
                        />
                        <h3>amitairi.com</h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="group-2">
                        <div className="info-details">
                          <h2>Ashish Wadhwa</h2>
                          <p>Realtor®</p>
                          <h3>519-221-3601</h3>
                          <h3>ashish@amitairi.com</h3>
                        </div>
                        <img className="dealer_img" src={rightman} alt="Ashish" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text_blue mt-4 mb-0 pb-4">
              Base house prices include all sales taxes (HST). Rebate signed
              back to builder on closing. Prices and promotions are subject to
              change without notice. Please see sales professional for details.
              E. &amp; O. E. Prices effective May 10, 2024
            </p>
          </div>
        </section>
      </>

    );


};

export default DealerInfo;