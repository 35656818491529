import React, { useState } from "react";
import { useEffect, useRef } from "react";
import WestHeader from "./WestHeader";
import logo2 from "./../../images/westwood/westwoood-logo.svg";
import banner from "./../../images/westwood/banner.webp";
import welcomeimg from "./../../images/westwood/welcome-img.svg";
import buttericon from "./../../images/westwood/butter-icon.svg";
import Crescentlogo from "./../../images/westwood/Crescent-Homes.svg";
import img1 from "./../../images/westwood/img01.png";
import img2 from "./../../images/westwood/img02.png";
import icon1 from "./../../images/westwood/bed.svg";
import icon2 from "./../../images/westwood/bath.svg";
import icon3 from "./../../images/westwood/car.svg";
import map from "./../../images/westwood/map1.webp";
import map2 from "./../../images/westwood/map2.webp";
import icon01 from "./../../images/westwood/icon01.svg";
import icon02 from "./../../images/westwood/icon02.svg";
import icon03 from "./../../images/westwood/icon03.svg";
import icon04 from "./../../images/westwood/icon04.svg";
import icon05 from "./../../images/westwood/icon05.svg";
import icon06 from "./../../images/westwood/icon06.svg";
import icon07 from "./../../images/westwood/icon07.svg";
import img01 from "./../../images/westwood/image1.webp";
import img02 from "./../../images/westwood/image2.webp";
import img03 from "./../../images/westwood/image3.webp";
import img04 from "./../../images/westwood/image4.webp";
import vedio from "./../../images/westwood/vedio.webp";
import img5 from "./../../images/westwood/image5.webp";
import img6 from "./../../images/westwood/image6.webp";
import img7 from "./../../images/westwood/list.svg";
import img8 from "./../../images/westwood/image7.webp";
// import url from "./../../images/westwood/demoVideo.mp4"
import "./westwood.css";
import { Link } from "react-router-dom";
import ContactNew from "./ContactNew";
import ContactUsWestWood from "./ContactUsWestWood";
import WestFooter from "./WestFooter";

const WestwoodWebsite = () => {
  const [menu, setMenu] = useState("community");
  const communityRef = useRef(null);
  const homesRef = useRef(null);
  const SiteMapRef = useRef(null);
  const neighbourRef = useRef(null);
  const communityBrochureRef = useRef(null);
  const contactRef = useRef(null);
  const handleScroll = (ref, name) => {
    const offset = 100;
    const sectionPosition =
      ref?.current?.getBoundingClientRect()?.top + window?.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
    setMenu(name);
  };

  return (
    <main id="woodweb">
      <WestHeader
        menuName={menu}
        communityRef={communityRef}
        homesRef={homesRef}
        SiteMapRef={SiteMapRef}
        neighbourRef={neighbourRef}
        communityBrochureRef={communityBrochureRef}
        contactRef={contactRef}
        handlePage={handleScroll}
      />

      <section className="banner_sec1" ref={communityRef}>
        <img className="banner_wood w-100" src={banner} />
        <div className="container">
          <div className="wbanner_text">
            <img src={logo2} />
            <h1>Inhale • Indulge • UnWIND</h1>
          </div>
        </div>
      </section>

      <section className="sec-padd wel_sec  bg-light01">
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-md-7">
              <img className="w-100" src={welcomeimg} />
            </div>
            <div className="col-md-5">
              <div className="end_img">
                <img src={buttericon} />
              </div>
              <h2 className="heading_w">
                Welcome TO <br /> <b>Westwood Village</b>
              </h2>
              <div className="flex_logo">
                by <img src={Crescentlogo} />
              </div>
              <p>
                In the heart of Cambridge lies Westwood Village, a sanctuary
                where luxury dances with serenity, and modern living embraces
                the timeless beauty of nature. Here, detached homes and
                townhouses, each a masterpiece of elegance, rest amid lush
                greenery and scenic tranquility.
              </p>
              <p>
                At its heart, Mariposa enchants as a haven near a shimmering
                water body, where mornings awaken to nature's soft serenade, and
                every moment feels like poetry a harmonious escape into life's
                most extraordinary retreat.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd select_home  bg-light02" ref={homesRef}>
        <div className="container">
          <h2 className="heading_w  text-center">
            Select <span> your new</span> <b> Homes</b> in <br />
            <b>Westwood Village</b>
          </h2>

          <div className="row g-md-5 g-4 justify-content-between">
            <div className="col-md-6">
              <h3 className="heading2_w">Single Detached Homes</h3>
              <p>
                Lörem ipsum pode menscertifiera. Skuldkvotstak kristofobi för
                att nissade då valig någonstansare . Dåpovis in. Prere prell
                tesk karen. Ratöl valig termovision helikoptermamma.{" "}
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="heading2_w">Freehold Townhomes</h3>
              <p>
                Lörem ipsum pode menscertifiera. Skuldkvotstak kristofobi för
                att nissade då valig någonstansare . Dåpovis in. Prere prell
                tesk karen. Ratöl valig termovision helikoptermamma.{" "}
              </p>
            </div>
          </div>
          <div className="text-center">
            <div className="btn_with">
              <Link to="/communities/westwood/detached">Detached</Link>
              <Link to="/communities/westwood/townhomes">Townhomes</Link>
            </div>
          </div>
          <div className="row g-md-5 g-4 justify-content-between">
            <div className="col-md-6 col-lg">
              <div className="card_img">
                <img className="w-100" src={img1} />
                <h3>36’ Detached</h3>
                <h5>
                  Available in <b>2100 Sq ft</b> & <b>2359 Sq ft</b>
                </h5>
                <ul>
                  <li>
                    <img src={icon1} />
                    <span>3</span>
                  </li>
                  <li>
                    <img src={icon2} />
                    <span>1.5 + 1</span>
                  </li>
                  <li>
                    <img src={icon3} />
                    <span>1 Car Garage</span>
                  </li>
                </ul>
                <Link href="#" className="btn_dark">
                  View home
                </Link>
              </div>
            </div>
            <div className="col-lg-1 p-0"></div>
            <div className="col-md-6 col-lg">
              <div className="card_img">
                <img className="w-100" src={img2} />
                <h3>36’ Detached</h3>
                <h5>
                  Available in <b>2100 Sq ft</b> & <b>2359 Sq ft</b>
                </h5>
                <ul>
                  <li>
                    <img src={icon1} />
                    <span>3</span>
                  </li>
                  <li>
                    <img src={icon2} />
                    <span>1.5 + 1</span>
                  </li>
                  <li>
                    <img src={icon3} />
                    <span>1 Car Garage</span>
                  </li>
                </ul>
                <Link href="#" className="btn_dark">
                  View home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-padd map_home bg-light01" ref={SiteMapRef}>
        <div className="container">
          <div className="heading_img">
            <h2 className="heading_w d-font">Site Map</h2>
            <img src={buttericon} />
          </div>
          <div className="container">
            <img className="pb-5 w-100" src={map} />
          </div>
          <div className="row g-md-5 g-4 align-items-center">
            <div className="col-md-6 ">
              <div className="flex_logo justify-content-center">
                <img src={Crescentlogo} />
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="most-font fw-semibold">About Site Map</h4>
              <p className="most-font">
                In the heart of the Mariposa neighborhood, you’ll find yourself
                surrounded by beauty as striking as its namesake. Strolling the
                trails, you might even be fortunate enough to spot the elusive
                Baltimore checkerspot butterfly, a rare and endangered treasure
                fluttering through this serene haven.
              </p>
            </div>
          </div>
          <div className="sec-padd-t">
            <div className="col-md-12">
              <ContactNew />
            </div>
          </div>
        </div>
      </section>

      <section
        className="sec-padd map_home Neighbourhoods_sec bg-light02"
        ref={neighbourRef}
      >
        <div className="container">
          <div className="heading_img">
            <h2 className="heading_w d-font">Neighbourhoods</h2>
            <img src={buttericon} />
          </div>

          <img className="pb-5 w-100" src={map2} />

          <div className="row g-md-5 g-4 align-items-end ">
            <div className="col-md-6 ">
              <div className="flex_logo justify-content-center">
                <h3 className="heading3_w">
                  A carefully crafted community made up of seven unique
                  neighborhoods.
                </h3>
              </div>
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon01} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon02} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon03} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon04} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon05} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon06} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
              <div className="icon_box">
                <div className="icon_img">
                  <img src={icon07} />
                </div>
                <div className="icon_content">
                  <h5>Mariposa</h5>
                  <p>
                    In the heart of the Mariposa neighborhood, you’ll find
                    yourself surrounded by beauty as striking as its namesake.
                    Strolling the trails, you might even be fortunate enough to
                    spot the elusive Baltimore checkerspot butterfly, a rare and
                    endangered treasure fluttering through this serene haven.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd community_w bg-light01">
        <div className="container">
          <div className="heading_img">
            <h2 className="heading_w d-font">A Community Rooted in Heritage</h2>
            <img src={buttericon} />
          </div>
          <div className="col-md-10   m-auto pb-4">
            <p className="text-center">
              Cambridge stands as a modern city with deep historical roots.
              Nestled at the meeting point of the Grand and Speed Rivers, it
              flourishes with lively restaurants, unique shops, and thriving
              businesses that cater to every lifestyle.
            </p>
          </div>

          <img className="pb-5 mb-5 w-100" src={img01} />

          <div className="row g-md-5 pb-5 g-4 align-items-center">
            <div className="col-md-6">
              <img className="pb-5 w-100" src={img02} />
            </div>
            <div className="col-md-6">
              <h4 className="heading4_w">Embrace Nature's Rewards</h4>
              <p>
                Cambridge is an outdoor lover's paradise, with a wealth of lush
                natural greenery at every turn. Bike or stroll the beautiful
                trail systems and vast parks, and canoe or kayak your way along
                the miles of winding river.
              </p>
              <p>
                Westwood Village is a proud partner of the Rare Charitable
                Research Reserve, an urban land trust and environmental
                institute that protects almost 1,000 acres of highly sensitive
                lands.
              </p>
            </div>
          </div>
          <div className="row g-md-5 g-4 align-items-center">
            <div className="col-md-6 order-md-2">
              <img className="pb-5 w-100" src={img03} />
            </div>
            <div className="col-md-6">
              <h4 className="heading4_w">Embrace Nature's Rewards</h4>
              <p>
                Cambridge is an outdoor lover's paradise, with a wealth of lush
                natural greenery at every turn. Bike or stroll the beautiful
                trail systems and vast parks, and canoe or kayak your way along
                the miles of winding river.
              </p>
              <p>
                Westwood Village is a proud partner of the Rare Charitable
                Research Reserve, an urban land trust and environmental
                institute that protects almost 1,000 acres of highly sensitive
                lands.
              </p>
            </div>
          </div>
        </div>
        <div className="sec-padd-t">
          <div className="container">
            <ContactNew />
            <div className="heading_img mt-5 pt-5" ref={communityBrochureRef}>
              <h2 className="heading_w d-font">Community Brochure</h2>
              <img src={buttericon} />
            </div>

            <div className="col-md-10 m-auto mt-5 pb-5">
              <img className="shadow_img" src={img04} />
              <div className="text-center sec-padd-t">
                {/* <Link href="#" className="btn_w">
                  download brochure
                </Link> */}

                <a
                  className="btn_w"
                  href="https://drive.google.com/file/d/1utTT-SyjLMdMSwCWmhY16xB8B-FpiyYm/view"
                  target="_blank"
                >
                  download brochure
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd community_a bg-light02">
        <div className="container">
          <div className="heading_img">
            <h2 className="heading_w d-font">Community Amenities</h2>
            <img src={buttericon} />
          </div>

          <div className="row g-md-5 g-4 justify-content-center">
            <div className="col-md-3">
              {/* <img src={vedio} /> */}
              <div
                style={{
                  position: "relative",
                  paddingBottom: "100%", // 16:9 ratio (9 / 16 = 0.5625 or 56.25%)
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  background: "#000",
                }}
              >
                {/* <iframe
        src={"https://drive.google.com/file/d/1uqWw1JrihUVShhdDbX9WgV_W1Xkdaa_O/view"}
        frameBorder="0"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title="Video"
      /> */}
                <iframe
                  width="300"
                  height="320"
                  src="https://www.youtube.com/embed/fDFHo3f7aMo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                {/* <video width="300" height="320" controls autoplay>
    <source   src={"https://www.youtube.com/shorts/fDFHo3f7aMo"} type="video/mp4"/>
</video> */}
              </div>
            </div>
            <div className=" offset-md-1 col-md-6">
              <h3 className=" pt-4">Bismark Pak</h3>
              <p>
                The park features a splash pad, spray wall, junior and senior
                playgrounds, junior and intermediate skate parks, a pavilion
                with on-site washrooms and change rooms, a tennis court, a
                pickleball court, a three-on-three basketball court, fitness
                stations, table games areas, a disc golf course, an amphitheatre
                and trails connecting to schools and other trails.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd Amenities_sec bg-light01">
        <div className="container">
          <div className="heading_img">
            <h2 className="heading_w d-font">Amenities Map</h2>
            <img src={buttericon} />
          </div>

          <div className="row g-md-5 g-4">
            <div className="col-md-8">
              <img src={img5} />
            </div>
            <div className="col-md-4">
              <img src={img6} />
              <div className="img_div">
                <img src={img7} />
              </div>
            </div>
          </div>
          <div className="sec-padd-t">
            <div className="row g-md-5 g-4">
              <div className="col-md-6">
                <img src={img8} />
              </div>
              <div className="col-md-6" ref={contactRef}>
                <ContactUsWestWood />
              </div>
            </div>
          </div>
        </div>
      </section>

      <WestFooter />
    </main>
  );
};

export default WestwoodWebsite;
