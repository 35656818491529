import React, { useState } from "react";
import { useEffect, useRef } from "react";
import WestHeader from "./WestHeader";
import logo2 from "./../../images/westwood/westwoood-logo.svg";
import banner01 from "./../../images/westwood/detached/Exterior1.webp";
import banner02 from "./../../images/westwood/detached/Exterior2.webp";
import westlogo from "./../../images/westwood/detached/westwood-logo.svg";

import img04 from "./../../images/westwood/detached/Brochure.webp";
import park from "./../../images/westwood/detached/park.webp";

import welcomeimg from "./../../images/westwood/welcome-img.svg";
import buttericon from "./../../images/westwood/butter-icon.svg";
import Crescentlogo from "./../../images/westwood/Crescent-Homes.svg";
import img1 from "./../../images/westwood/img1.webp";
import img2 from "./../../images/westwood/img2.webp";
import icon1 from "./../../images/westwood/detached/icon01.svg";
import icon2 from "./../../images/westwood/detached/icon02.svg";
import icon3 from "./../../images/westwood/detached/icon03.svg";
import icon4 from "./../../images/westwood/detached/icon04.svg";

import map from "./../../images/westwood/map1.webp";
import map2 from "./../../images/westwood/map2.webp";

import vedio from "./../../images/westwood/vedio.webp";
import img5 from "./../../images/westwood/detached/map01.webp";
import img6 from "./../../images/westwood/detached/upar-map.webp";
import img7 from "./../../images/westwood/list.svg";
import img8 from "./../../images/westwood/detached/contact.webp";
import img9 from "./../../images/westwood/naksha.webp";

import "./westwood.css";
import { Link, useNavigate } from "react-router-dom";
import ContactNew from "./ContactNew";
import DealerInfo from "./DealerInfo";

import ContactUsWestWood from "./ContactUsWestWood";
import WestFooter from "./WestFooter";

const Townhomes = () => {
  const [menu, setMenu] = useState("community");
  const communityRef = useRef(null);
  const homesRef = useRef(null);
  const SiteMapRef = useRef(null);
  const neighbourRef = useRef(null);
  const communityBrochureRef = useRef(null);
  const contactRef = useRef(null);
  const navigate = useNavigate();
  const handleScroll = (ref, name) => {
    // navigate('/communities/westwood')
    const offset = 100;
    const sectionPosition =
      ref?.current?.getBoundingClientRect()?.top + window?.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
    setMenu(name);
  };

  return (
    <main id="woodweb" className="inner_wood">
      <WestHeader
        menuName={menu}
        communityRef={communityRef}
        homesRef={homesRef}
        SiteMapRef={SiteMapRef}
        neighbourRef={neighbourRef}
        communityBrochureRef={communityBrochureRef}
        contactRef={contactRef}
        handlePage={handleScroll}
      />

      <section className="sec-padd   bg-light01 inner_first">
        <div className="container">
          <div className="row align-items-center g-md-5 g-4">
            <div className="col-md-4 text-center text-md-start mb-5 ">
              <img src={westlogo} />
            </div>
            <div className="col-md-8">
              <h2 className="cofee-color fw-medium text-uppercase text-center text-md-start">
                Single Detached Homes
              </h2>
              <p className="fw-medium pt-3">
                Welcome to our stunning collection of detached homes, featuring
                30- and 36-foot frontages with spacious layouts ranging from
                1,737 to 2,047 square feet. Expertly designed with a harmonious
                blend of stone, brick, metal, and vinyl, these homes boast
                modern elegance and timeless appeal. Enjoy expansive
                open-concept floor plans tailored to suit your lifestyle.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd1 pt-2 bg-light01 inner_first margin_2rem">
        <div className="container">
          <div className="bg_bark mt-4 p-0">
            <div className="row g-md-5 g-4" ref={homesRef}>
              <div className="col-md-6 mt-0">
                <img src={banner01} />
              </div>
              <div className="col-md-6 mt-md-0 mt-5">
                <img src={banner02} />
              </div>
              <div className="col-md-12">
                <h2 className="heading2_w p-4 pt-2 pb-2 fw-bolder text-center">
                  More information coming soon
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd map_home bg-light01" >
        <div className="container">
          <div className="sec-padd-b">
            <div className="col-md-12">
              <ContactNew />
            </div>
          </div>
          <div className="heading_img" ref={SiteMapRef}>
            <h2 className="heading_w d-font">Site Map</h2>
            <img src={buttericon} />
          </div>
          <div className="container">
            <img className="pb-5 w-100" src={map} />
          </div>
          <div className="row g-md-5 g-4 align-items-center">
            <div className="col-md-6 ">
              <div className="flex_logo justify-content-center">
                <img src={Crescentlogo} />
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="most-font fw-semibold">About Site Map</h4>
              <p className="most-font">
                In the heart of the Mariposa neighborhood, you’ll find yourself
                surrounded by beauty as striking as its namesake. Strolling the
                trails, you might even be fortunate enough to spot the elusive
                Baltimore checkerspot butterfly, a rare and endangered treasure
                fluttering through this serene haven.
              </p>
            </div>
          </div>
          <div className="sec-padd-b pt-5">
            <div className="heading_img mt-5 pt-5" ref={communityBrochureRef}>
              <h2 className="heading_w d-font">Community Brochure</h2>
              <img src={buttericon} />
            </div>

            <div className="col-md-10 m-auto mt-5 pb-5">
              <img className="shadow_img" src={img04} />
              <div className="text-center sec-padd-t">
                {/* <Link href="#" className="btn_w">
                  download brochure
                </Link> */}

                <a
                  className="btn_w"
                  href="https://drive.google.com/file/d/1utTT-SyjLMdMSwCWmhY16xB8B-FpiyYm/view"
                  target="_blank"
                >
                  download brochure
                </a>
              </div>
            </div>
          </div>
          <ContactNew />
        </div>
      </section>

      <section className="sec-padd community_a bg-light02" >
        <div className="container">
          <div className="row g-md-5 g-4 justify-content-center align-items-center">
            <div className="col-md-4">
              <img src={park} />
            </div>
            <div className=" offset-md-1 col-md-6">
              <div className="heading_img justify-content-md-start">
                <h2 className="heading_w d-font">Community Amenities</h2>
                <img src={buttericon} />
              </div>
              <h3>Bismark Pak</h3>
              <p>
                The park features a splash pad, spray wall, junior and senior
                playgrounds, junior and intermediate skate parks, a pavilion
                with on-site washrooms and change rooms, a tennis court, a
                pickleball court, a three-on-three basketball court, fitness
                stations, table games areas, a disc golf course, an amphitheatre
                and trails connecting to schools and other trails.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd Amenities_sec bg-light01" ref={neighbourRef}>
        <div className="container">
          <div className="heading_img">
            <h2 className="heading_w d-font">Amenities Map</h2>
            <img src={buttericon} />
          </div>

          <div className="row g-md-5 g-4">
            <div className="col-md-8">
              <img src={img5} />
            </div>
            <div className="col-md-4">
              <img src={img6} />
              <div className="img_div">
                <img src={img7} />
              </div>
            </div>
          </div>
          <div className="sec-padd-t">
            <div className="row g-md-5 g-4">
              <div className="col-md-6">
                <img src={img8} />
              </div>
              <div className="col-md-6" ref={contactRef}>
                <ContactUsWestWood />
              </div>
            </div>
          </div>
          <div className="sec-padd-t">
            <DealerInfo />
          </div>
        </div>
      </section>

      <WestFooter />
    </main>
  );
};

export default Townhomes;
