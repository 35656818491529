import React, { useState, useEffect, useRef } from "react";
import logo from "./../../images/westwood/logo.svg";

function WestHeader(props) {
  const [headerClass, setHeaderClass] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderClass("scrolled");
      } else {
        setHeaderClass("");
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`westwood_header ${headerClass}`}>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="" style={{ height: "40px" }} />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      props?.menuName === "community" ? "activeMenu" : ""
                    }`}
                    aria-current="page"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePage(props.communityRef, "community");
                    }}
                  >
                    Community
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      props?.menuName === "homes" ? "activeMenu" : ""
                    }`}
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePage(props.homesRef, "homes");
                    }}
                  >
                    Our homes
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      props?.menuName === "siteMap" ? "activeMenu" : ""
                    }`}
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePage(props.SiteMapRef, "siteMap");
                    }}
                  >
                    site Map
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      props?.menuName === "neighbourhood" ? "activeMenu" : ""
                    }`}
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePage(props.neighbourRef, "neighbourhood");
                    }}
                  >
                    Neighbourhood
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      props?.menuName === "communityBrochureRef"
                        ? "activeMenu"
                        : ""
                    }`}
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePage(
                        props.communityBrochureRef,
                        "communityBrochureRef"
                      );
                    }}
                  >
                    Community Brochure
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      props?.menuName === "contact" ? "activeMenu" : ""
                    }`}
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlePage(props.contactRef, "contact");
                    }}
                  >
                    Contact
                  </a>
                </li>

                {/* {   props?.logoWhite && <li className="nav-item">
                  <a className="nav-link" href="#Register"
                   onClick={(e) => {
                    e.preventDefault();
                    props?.handlePage(props?.contactRef, "Register");
                  }}
                  >Contact</a>
                </li>} */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default WestHeader;
