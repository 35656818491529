import React, { useState } from "react";
import { useEffect, useRef } from "react";
import "./south.css";

import logo1 from "./../../images/southcreek/logo-icon.svg";
import logo from "./../../images/southcreek/logo.svg";
import logo2 from "./../../images/southcreek/logo2.svg";
import lefttree from "./../../images/southcreek/left-tree.svg";
import righttree from "./../../images/southcreek/right-tree.svg";
import img1 from "./../../images/southcreek/img1.webp";
import map from "./../../images/southcreek/map.webp";

import map1 from "./../../images/southcreek/map1.webp";
import map2 from "./../../images/southcreek/map2.webp";
import map3 from "./../../images/southcreek/map3.webp";
import map4 from "./../../images/southcreek/map4.webp";
import sitemap from "./../../images/southcreek/site-map.webp";
import logo01 from "./../../images/southcreek/logo1.png";
import logo02 from "./../../images/southcreek/logo2.png";
import logo03 from "./../../images/southcreek/logo3.png";
import logo04 from "./../../images/southcreek/logo4.png";
import logo05 from "./../../images/southcreek/logo5.png";
import darkimg from "./../../images/southcreek/dark-img.webp";
import mobilewar from "./../../images/southcreek/mobile-war.webp";
import logo3 from "./../../images/southcreek/logo3.svg";
import logodark from "./../../images/southcreek/logo-dark.svg";
import renderview from "./../../images/southcreek/render-view.webp";
import footerlogo1 from "./../../images/southcreek/savemax-logo.png";
import leftman from "./../../images/southcreek/img02.webp";
import footerlogo from "./../../images/southcreek/footer-logo.svg";
import rightman from "./../../images/southcreek/img01.webp";
import ContactUsSouth from "./ContactUsSouth";

const SouthCreekWebsite = () => {
  const headerRef = useRef(null);
  const [menu, setMenu] = useState("Community");
  useEffect(() => {
    const toggleHeaderClass = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add("scrolled");
      } else {
        headerRef.current.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", toggleHeaderClass);

    return () => {
      window.removeEventListener("scroll", toggleHeaderClass);
    };
  }, []);

  const CommunityRef = useRef(null);
  const LocationRef = useRef(null);
  const FloorplansRef = useRef(null);
  const SitemapRef = useRef(null);
  const BuilderRef = useRef(null);
  const WarrantyRef = useRef(null);
  const OpenhouseRef = useRef(null);
  const ContactRef = useRef(null);

  const handleScroll = (ref, name) => {
    const offset = 100;
    const sectionPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
    setMenu(name);
  };

  console.log("menu", menu);
  return (
    <>
      <main id="south_design">
        <header id="main-header" ref={headerRef}>
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <a className="navbar-brand" href="/">
                {/* <img src={logo1} alt="" /> */}
                <img src={logo2} alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto align-items-center">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Community" ? "activeMenu" : ""
                      }`}
                      aria-current="page"
                      href="#Community"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(CommunityRef, "Community");
                      }}
                    >
                      Community
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Location" ? "activeMenu" : ""
                      }`}
                      href="#Location"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(LocationRef, "Location");
                      }}
                    >
                      Location
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Floor" ? "activeMenu" : ""
                      }`}
                      href="#Floorplans"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(FloorplansRef, "Floor");
                      }}
                    >
                      Floor Plan
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Site" ? "activeMenu" : ""
                      }`}
                      href="#Sitemap"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(SitemapRef, "Site");
                      }}
                    >
                      Site Map
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Builder" ? "activeMenu" : ""
                      }`}
                      href="#Builder"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(BuilderRef, "Builder");
                      }}
                    >
                      Builder
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Warranty" ? "activeMenu" : ""
                      }`}
                      href="#Warranty"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(WarrantyRef, "Warranty");
                      }}
                    >
                      Warranty
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Open" ? "activeMenu" : ""
                      }`}
                      href="#Openhouse"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(OpenhouseRef, "Open");
                      }}
                    >
                      Open House
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        menu === "Contact" ? "activeMenu" : ""
                      }`}
                      href="#Contact"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(ContactRef, "Contact");
                      }}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <section className="banner-section sec-padd">
          <div className="container">
            <div className="banner-text">
              <img src={logo} alt="logo" />
              <img src={logo2} alt="logo" />
            </div>
          </div>
        </section>

        <section
          ref={CommunityRef}
          id="Community"
          className="sec-padd bg-light1"
        >
          <img className="left_tree" src={lefttree} alt="" />
          <img className="right_tree" src={righttree} alt="" />
          <div className="container">
            <div className="col-md-8 m-auto">
              <p className="title">The Community</p>
              <h2 className="c_heading blue-c">SOUTH CREEK</h2>
              <p className="dark_p">
                Nestled in the southern end of Kitchener, this Doon
                neighbourhood features an abundance of parks and natural areas –
                part of why it plays host to so many family-centric communities.
                Close to a wide array of amenities, schools, and just minutes
                away from the 401, it’s no surprise that the South Creek site is
                the centerpiece of this neighbourhood. Thoughtfully-designed,
                spacious interiors are beautifully appointed – and the result
                isn’t just a home... it’s an oasis. Natural beauty, creative
                architectural designs, modern construction methods, and superior
                finishes – it’s a winning combination that sets this development
                apart from the rest. Ensconced in family-focused neighbourhoods
                and environmentally- preserved greenspaces, South Creek captures
                the best of both worlds. While close enough to the city to enjoy
                its conveniences and amenities, it’s nestled by kilometres of
                trails that offer plenty of room to roam.
              </p>
            </div>
          </div>
        </section>
        <section className="s_img">
          <img src={img1} alt="" />
        </section>
        <section className="bg-blue sec-padd">
          <div className="container">
            <h2 className="c_heading text-white text-center">
              hit a home run!
            </h2>
            <div className="row g-md-5 g-4">
              <div className="col-md-4">
                <div className="card_div">
                  <h3>Make a Smart Investment Choice</h3>
                  <p>
                    The likability, desirability, and popularity of the Doon
                    South neighbourhood creates an upward trend in property
                    value, directly benefiting the investment potential of your
                    new home. Choosing a home at South Creek Towns means not
                    only enjoying a comfortable, modern living space but also
                    making a smart financial decision.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card_div">
                  <h3>Make a Smart Investment Choice</h3>
                  <p>
                    The likability, desirability, and popularity of the Doon
                    South neighbourhood creates an upward trend in property
                    value, directly benefiting the investment potential of your
                    new home. Choosing a home at South Creek Towns means not
                    only enjoying a comfortable, modern living space but also
                    making a smart financial decision.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card_div">
                  <h3>Make a Smart Investment Choice</h3>
                  <p>
                    The likability, desirability, and popularity of the Doon
                    South neighbourhood creates an upward trend in property
                    value, directly benefiting the investment potential of your
                    new home. Choosing a home at South Creek Towns means not
                    only enjoying a comfortable, modern living space but also
                    making a smart financial decision.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Location" ref={LocationRef} className="bg-light1  map-sec">
          <div className="container">
            <div className="row   mt-0 g-0 align-items-center">
              <div className="col-md-7">
                <img src={map} alt="" />
              </div>
              <div className="col-md-5">
                <p className="title">About The</p>
                <h2 className="c_heading blue-c">Location</h2>
                <p className="title2">that will NEVER LOSE ITS LUSTRE</p>
                <p className="dark_p">
                  On the South end of Kitchener, located just off New Dundee
                  Road and HWY 401, South Creek Towns offers an ideal location
                  for everything your lifestyle demands.
                </p>

                <p className="dark_p">
                  Doon South, one of the most desirable neighbourhoods in
                  Kitchener and South Creek Towns unite community and nature
                  perfectly. It is a sanctuary for nature lovers surrounded by
                  kilometres of trails and greenspace. At South Creek, everyday
                  living is easy with everything accessible around the corner. A
                  quick run to grocery stores, restaurants, gym and coffee shops
                  or a leisure stroll in a park or a trail, all is within your
                  arm’s reach. The quick access to the Highway 401 and several
                  transit options, makes getting to work or exploring the
                  region a breeze.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          className="sec-padd bg-dark1"
          id="Floorplans"
          ref={FloorplansRef}
        >
          <div className="container">
            <h2 className="c_heading text-white text-center pb-4">
              Floor Plans
            </h2>
            <div className="row g-4">
              <div className="col-md-3 col-sm-6">
                <div className="img_card">
                  <img src={map1} alt="" />
                  <div className="link_div">
                    <a
                      href="https://drive.google.com/file/d/1nw6wbc_7LPC1kPO1xHzf-_JfgIi33Jme/view"
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="img_card">
                  <img src={map2} alt="" />
                  <div className="link_div">
                    <a
                      href="https://drive.google.com/file/d/1YiMsiuOaCAQLvE3lVE63NDcU_mNWSVSc/view"
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="img_card">
                  <img src={map3} alt="" />
                  <div className="link_div">
                    <a
                      href="https://drive.google.com/file/d/1DBoNi-Iu_-2DaqnT0DP4W6Fp07XabSkD/view"
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="img_card">
                  <img src={map4} alt="" />
                  <div className="link_div">
                    <a
                      href="https://drive.google.com/file/d/1LHnLH4WeyO19p3vfhxffaKrxwMXJ8Uvu/view"
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-padd bg-dark1" id="Sitemap" ref={SitemapRef}>
          <div className="container">
            <h2 className="c_heading text-white text-center pb-4">site map</h2>
            <div className="col-md-10 m-auto">
              <img src={sitemap} alt="" />
            </div>
          </div>
        </section>
        <section
          className="sec-padd bg-blue builder_Sec"
          id="Builder"
          ref={BuilderRef}
        >
          <div className="container">
            <h2 className="c_heading text-white text-center pb-4">
              The Builder
            </h2>
            <div className="row g-4 align-items-center">
              <div className="col-md-6 text-center">
                <img className="logo-builder" src={logo2} alt="" />
              </div>
              <div className="col-md-6">
                <p>
                  At Crescent Homes, we believe in enhancing the quality of
                  life. We offer prime locations, innovative products, excellent
                  customer service, and competitive pricing. A qualified team
                  meticulously crafting every home, ensuring attention to detail
                  and customer satisfaction. We prioritize energy efficiency,
                  health, and comfort in our homes, tailoring each build to meet
                  the unique needs of every buyer. Crescent Homes' commitment
                  extends beyond construction; we strive to foster trust, build
                  strong relationships, and attain peace of mind for our buyers.
                </p>
                <p>
                  Our knowledgeable and passionate team collaborates closely
                  with buyers throughout the process to make their vision a
                  reality. We strive to uphold the values of innovation,
                  integrity, and excellence in everything we do, providing
                  employees with a supportive work environment in which they can
                  deliver exceptional craftsmanship and service while
                  maintaining professional integrity.
                </p>
                <p>
                  Our mission is to provide customers with high-quality,
                  dependable homes that they will be proud of for years. We
                  value professionalism, integrity, and fairness in all of our
                  interactions.
                </p>
              </div>
            </div>
            <div className="row g-md-5 g-4 align-items-center logo-sec">
              <div className="col-md-12">
                <p className="title project_p">
                  <span>More Projects by</span> Crescent Homes
                </p>
              </div>
              <div className="col-md col-sm-4">
                <img src={logo01} alt="" />
              </div>
              <div className="col-md col-sm-4">
                <img src={logo02} alt="" />
              </div>
              <div className="col-md col-sm-4">
                <img src={logo03} alt="" />
              </div>
              <div className="col-md col-sm-4">
                <img src={logo04} alt="" />
              </div>
              <div className="col-md col-sm-4">
                <img src={logo05} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="warrenty_sec " id="Warranty" ref={WarrantyRef}>
          <img src={darkimg} className="w-100 m-hide" alt="" />
          <img src={mobilewar} className="w-100 m-show" alt="" />

          <div className="container">
            <div className="dark_text">
              <div className="heading_div">
                <div>
                  <p className="title text-white">CRESCENT HOMES</p>
                  <h2 className="c_heading text-white ">WARRANTY</h2>
                </div>
                <div className="m-show">
                  <img src={logo3} alt="" />
                </div>
              </div>

              <p className="text-white">
                As a proud Tarion-registered builder, we are dedicated to
                providing you with the highest standards of quality and consumer
                protection. Our registration with Tarion ensures that your new
                home is backed by a comprehensive warranty program, giving you
                peace of mind from the moment you receive your keys. This
                commitment to excellence and adherence to Tarion’s stringent
                guidelines guarantees that your home is protected, allowing you
                to enjoy your new home with confidence. Trust us to deliver not
                just a house, but a place you’ll love to call home.
              </p>
              <p className="text-center m-hide">
                <img src={logo3} alt="" />
              </p>
            </div>
          </div>
        </section>
        <section
          className="map_location bg-light1 footer-form"
          id="Openhouse"
          ref={OpenhouseRef}
        >
          <div className="container">
            <div className="row g-0">
              <div className="col-md-6 text-center">
                <img className="pt-4" src={logodark} alt="" />
                <h2 className="pt-4 c_heading">WARRANTY</h2>
                <p className="title">OPEN EVERY</p>
                <p className="dark_p">Saturday & Sunday • 12:00 to 4:00PM</p>
                <p className="dark_p">277 Chapel Hill Drive, Kitchener</p>
                <div className="map_div">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2899.9123380928877!2d-80.45345062397449!3d43.37885697111643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c754c471ba79d%3A0xf4238e5e4038753d!2s277%20Chapel%20Hill%20Dr%2C%20Kitchener%2C%20ON%20N2R%201N3%2C%20Canada!5e0!3m2!1sen!2sin!4v1725561437274!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-md-6">
                <img className="render_img" src={renderview} alt="" />
              </div>
            </div>
            <ContactUsSouth contactRef={ContactRef} id="Contact" />
            {/* <div className="col-md-6 m-auto" id="Contact" ref={ContactRef}>
              <div className="custom_width">
                <h2 className="pt-5 mt-4 c_heading">Request a Call Back</h2>
                <form action="">
                  <div className="row g-4">
                    <div className="col-md-6">
                      <input type="text" name="" id="" placeholder="First Name" />
                    </div>
                    <div className="col-md-6">
                      <input type="text" name="" id="" placeholder="Last Name" />
                    </div>
                    <div className="col-md-12">
                      <input type="email" name="" id="" placeholder="Email" />
                    </div>
                    <div className="col-md-12">
                      <input type="tel" name="" id="" placeholder="Phone" />
                    </div>
                    <div className="col-md-12">
                      <textarea name="" id="" cols="30" rows="5" placeholder="Your Message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <label>Are you currently working with a Realtor?</label>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radio" id="radio1" />
                        <label className="form-check-label"  >
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="radio" id="radio2"
                          checked="" />
                        <label className="form-check-label"  >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label>Are you a realtor?</label>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="realtor" id="radio3" />
                        <label className="form-check-label"  >
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="realtor" id="radio4"
                          checked="" />
                        <label className="form-check-label" >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <input type="submit" value="Submit Now" />
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </section>
        <footer className="sec-padd-t bg-light1">
          <div className="container">
            <div className="row g-4 ">
              <div className="col-md-7 m-auto sec-padd-b">
                <h2 className="c_heading text-center">
                  Exclusive Listing Brokerage
                </h2>
                <h4 className="sub-heading text-center">
                  {/* <span>RE/MAX</span> Save Max Real Estate Inc., Brokerage  */}
                  Save Max Real Estate Inc., Brokerage 
                </h4>
                <div className="m-show footer-logo-div">
                  <img src={footerlogo1} alt="" />
                  {/* <p>amitairi.com</p> */}
                </div>
              </div>

              <div className="footer-info">
                <div className="col-md-7 m-auto">
                  <div className="row g-4">
                    <div className="col-md-4">
                      <div className="group-1">
                        <img src={leftman} alt="" />
                        <div className="info-details">
                          <h2>Raman Dua</h2>
                          <p>Broker</p>
                          {/* <h3>519-623-0000</h3>
                          <h3>info@amitairi.com</h3> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 m-hide">
                      <div className="group-m">
                        {/* <img
                          className="position-relative"
                          src={footerlogo}
                          alt=""
                        /> */}
                        <h3>
                         <br></br>
                          {/* amitairi.com */}

                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="group-2">
                        <div className="info-details">
                          <h2>Kavi Sharma</h2>
                          {/* <p style={{textAlign:'right'}}>Realtor®</p> */}
                          <h3>+1 (647) 554-6212</h3>
                          {/* <h3>ashish@amitairi.com</h3> */}
                        </div>
                        <img src={rightman} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text_blue mt-4 mb-0 pb-4">
              Base house prices include all sales taxes (HST). Rebate signed
              back to builder on closing. Prices and promotions are subject to
              change without notice. Please see sales professional for details.
              E. &amp; O. E. Prices effective May 10, 2024
            </p>
          </div>
        </footer>
      </main>
    </>
  );
};

export default SouthCreekWebsite;
