import React, { useState } from "react";
import { useEffect, useRef } from "react";
import WestHeader from "./WestHeader";
import logo2 from "./../../images/westwood/westwoood-logo.svg";
import banner from "./../../images/westwood/banner.webp";
import banner1 from "./../../images/westwood/banner-detached.webp";
import map01 from "./../../images/westwood/map01.webp";
import map02 from "./../../images/westwood/detached/map02.webp";
import img04 from "./../../images/westwood/detached/Brochure.webp";
import park from "./../../images/westwood/detached/park.webp";

import welcomeimg from "./../../images/westwood/welcome-img.svg";
import buttericon from "./../../images/westwood/butter-icon.svg";
import Crescentlogo from "./../../images/westwood/Crescent-Homes.svg";
import img1 from "./../../images/westwood/img1.webp";
import img2 from "./../../images/westwood/img2.webp";
import icon1 from "./../../images/westwood/detached/icon01.svg";
import icon2 from "./../../images/westwood/detached/icon02.svg";
import icon3 from "./../../images/westwood/detached/icon03.svg";
import icon4 from "./../../images/westwood/detached/icon04.svg";

import map from "./../../images/westwood/map1.webp";
import map2 from "./../../images/westwood/map2.webp";

import vedio from "./../../images/westwood/vedio.webp";
import img5 from "./../../images/westwood/detached/map01.webp";
import img6 from "./../../images/westwood/detached/upar-map.webp";
import img7 from "./../../images/westwood/list.svg";
import img8 from "./../../images/westwood/detached/contact.webp";
import img9 from "./../../images/westwood/naksha.webp";

import "./westwood.css";
import { Link, useNavigate } from "react-router-dom";
import ContactNew from "./ContactNew";
import DealerInfo from "./DealerInfo";

import ContactUsWestWood from "./ContactUsWestWood";
import WestFooter from "./WestFooter";

const WestPage = () => {
  const [menu, setMenu] = useState("community");
  const communityRef = useRef(null);
  const homesRef = useRef(null);
  const SiteMapRef = useRef(null);
  const neighbourRef = useRef(null);
  const communityBrochureRef = useRef(null);
  const contactRef = useRef(null);
  const navigate = useNavigate();
  const handleScroll = (ref, name) => {
    const offset = 100;

    const sectionPosition =
      ref?.current?.getBoundingClientRect()?.top + window?.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
    setMenu(name);
  };

  return (
    <main id="woodweb" className="inner_wood">
      <WestHeader
        menuName={menu}
        communityRef={communityRef}
        homesRef={homesRef}
        SiteMapRef={SiteMapRef}
        neighbourRef={neighbourRef}
        communityBrochureRef={communityBrochureRef}
        contactRef={contactRef}
        handlePage={handleScroll}
      />

      <section>
        <img src={banner1} />
      </section>
      <section className="sec-padd pt-5 bg-light01 inner_first">
        <div className="container">
          <div className="heading_img mt-4" ref={homesRef}>
            <h2 className="heading_w d-font">Floor Plan</h2>
            <img src={buttericon} />
          </div>
          <div className="bg_bark mt-4 padding-1">
            <div className="row g-md-5 flex_column_m justify-content-between g-4">
              <div className="col-md-4">
                <label className="option1 mb-4">Option 1</label>
                <h3 class="heading2_w text-capitalize jose-font mb-2">
                  The Perfect Plan
                </h3>
                <p class="f-500">
                  Designed with your family’s needs in mind, this floor plan
                  combines practicality and style. Every detail has been
                  carefully considered, from open, light-filled living spaces to
                  spacious, well-appointed bedrooms and baths, offering both
                  comfort and sophistication. It’s perfect for everything from
                  casual mornings to festive gatherings, providing a great
                  setting to create memories that last a lifetime.
                </p>
                <div className="card_img sep_group mt-5">
                  <div className="col-md-8 col-10 m-auto">
                    <div className="row g-4">
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon1} />
                          <p>1,701 Sq. Ft.</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon2} />
                          <p>3</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon3} />
                          <p>2 + 1</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon4} />
                          <p>
                            1 Car <br /> Garage
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 pt-5 mb-5">
                    <a
                      href="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjKpek3i-_BjOp5R9nUEpR14-8V0KK5xHy5l8tCkVopLN-0OhSEwuGQFIKUTIB_83pgx_oYG-rBd3GvPpSf0x8pLP9eVyhOa6iPl1CichzpWlpDxX-VSeNhD837vKejkpPmoDXcKWeU3-H1iBggMVOSuXP3rp2qe7uWBkkJV-mJmcEhlkrUfu-prEQPPv8/s16000/unnamed%20%281%29.jpg"
                      target="_blank"
                      className="btn_dark big_btn"
                    >
                      download floor plan
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <img src={map01} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd1 pt-2 bg-light01 inner_first margin_2rem">
        <div className="container">
          <div className="bg_bark mt-4 padding-1">
            <div className="row g-md-5 flex_column_m justify-content-between g-4">
              <div className="col-md-4">
                <label className="option1 mb-4">Option 2</label>
                <h3 class="heading2_w text-capitalize jose-font mb-2">
                  The Perfect Plan
                </h3>
                <p class="f-500">
                  This unique layout features a divided main entrance, offering
                  separate access to the basement and the main house. It’s a
                  versatile design that provides potential for an in-law suite
                  while catering to a family’s future space needs and privacy. A
                  thoughtful solution for multigenerational living or those
                  seeking flexibility in their home.
                </p>
                <div className="card_img sep_group mt-5">
                  <div className="col-md-8 col-10 m-auto">
                    <div className="row g-4">
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon1} />
                          <p>1,701 Sq. Ft.</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon2} />
                          <p>3</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon3} />
                          <p>2 + 1</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-center">
                          <img src={icon4} />
                          <p>
                            1 Car <br /> Garage
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 pt-5 mb-5">
                    <a
                      href="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjwAyViAp-EcCB9x6Wl3CWjBv1bmbbSQhcT6lZskv2aVWMSxT7z4B15VhCN01U1gY5tc8AToMD2Bgp1R5eom-jZDlpTxgbij4QZ35tOMBGrlTeH28WJRi8eHaasXY_Bmpbjb46wZ62Gyl3l-FRQ724NXqo5rx2SpjYJ0rOi3zQ2Gg9jnT0jLzozPnbqhEM/s16000/unnamed.jpg"
                      target="_blank"
                      className="btn_dark big_btn"
                    >
                      download floor plan
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <img src={map02} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd map_home bg-light01">
        <div className="container">
          <div className="sec-padd-b">
            <div className="col-md-12">
              <ContactNew />
            </div>
          </div>
          <div className="heading_img" ref={SiteMapRef}>
            <h2 className="heading_w d-font">Site Map</h2>
            <img src={buttericon} />
          </div>
          <div className="container">
            <img className="pb-5 w-100" src={map} />
          </div>
          <div className="row g-md-5 g-4 align-items-center">
            <div className="col-md-6 ">
              <div className="flex_logo justify-content-center">
                <img src={Crescentlogo} />
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="most-font fw-semibold">About Site Map</h4>
              <p className="most-font">
                In the heart of the Mariposa neighborhood, you’ll find yourself
                surrounded by beauty as striking as its namesake. Strolling the
                trails, you might even be fortunate enough to spot the elusive
                Baltimore checkerspot butterfly, a rare and endangered treasure
                fluttering through this serene haven.
              </p>
            </div>
          </div>
          <div className="sec-padd-b pt-5">
            <div className="heading_img mt-5 pt-5" ref={communityBrochureRef}>
              <h2 className="heading_w d-font">Community Brochure</h2>
              <img src={buttericon} />
            </div>

            <div className="col-md-10 m-auto mt-5 pb-5">
              <img className="shadow_img" src={img04} />
              <div className="text-center sec-padd-t">
                {/* <Link href="#" className="btn_w">
                  download brochure
                </Link> */}

                <a
                  className="btn_w"
                  href="https://drive.google.com/file/d/1utTT-SyjLMdMSwCWmhY16xB8B-FpiyYm/view"
                  target="_blank"
                >
                  download brochure
                </a>
              </div>
            </div>
          </div>
          <ContactNew />
        </div>
      </section>

      <section className="sec-padd community_a bg-light02">
        <div className="container">
          <div className="row g-md-5 g-4 justify-content-center align-items-center">
            <div className="col-md-4">
              <img src={park} />
            </div>
            <div className=" offset-md-1 col-md-6">
              <div className="heading_img justify-content-md-start">
                <h2 className="heading_w d-font">Community Amenities</h2>
                <img src={buttericon} />
              </div>
              <h3>Bismark Pak</h3>
              <p>
                The park features a splash pad, spray wall, junior and senior
                playgrounds, junior and intermediate skate parks, a pavilion
                with on-site washrooms and change rooms, a tennis court, a
                pickleball court, a three-on-three basketball court, fitness
                stations, table games areas, a disc golf course, an amphitheatre
                and trails connecting to schools and other trails.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd Amenities_sec bg-light01">
        <div className="container" ref={neighbourRef}>
          <div className="heading_img">
            <h2 className="heading_w d-font">Amenities Map</h2>
            <img src={buttericon} />
          </div>

          <div className="row g-md-5 g-4">
            <div className="col-md-8">
              <img src={img5} />
            </div>
            <div className="col-md-4">
              <img src={img6} />
              <div className="img_div">
                <img src={img7} />
              </div>
            </div>
          </div>
          <div className="sec-padd-t">
            <div className="row g-md-5 g-4">
              <div className="col-md-6">
                <img src={img8} />
              </div>
              <div className="col-md-6" ref={contactRef}>
                <ContactUsWestWood />
              </div>
            </div>
          </div>
          <div className="sec-padd-t">
            <DealerInfo />
          </div>
        </div>
      </section>

      <WestFooter />
    </main>
  );
};

export default WestPage;
